import React, { Component } from 'react';

class Loader extends Component {
  render = () => {
    return (
      <div className='spinner'></div>
    )
  }
}

export default Loader;
